<template>
  <div id="contentUs">
    <t-header :nav-light="false"></t-header>
    <section class="bg-half bg-light d-table w-100" style="background: url('media/images/images/contact-detail.jpg') center center;">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Anti-Spam</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a @click="$router.push({name:'user.index'})">{{$t('index.home')}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Anti-Spam</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>  <!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- questions section begin -->
    <div class="questions-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
              <h1 class="turuncu-baslik">Anti-Spam Politikası</h1>
              <p>
                Alıcılarının ya da alıcısının isteği dışında gönderilmiş toplu elektronik posta mesajları SPAM olarak değerlendirilir. Napolyon.com, üyelerinin e-posta adreslerinin kendi kişisel bilgileri olduğunu kabul eder ve kendi istekleri dışında bu adreslere toplu olarak mesaj göndermeyeceğini taahhüt eder.
              </p>
              <p>
                Üyeler; dağıtım listelerine, haber gruplarına, satın alınmış e-posta adreslerine Napolyon.com'la ilgili herhangi bir postalama yapmayacaklarını üyelik sözleşmesini kabul ederek taahhüt eder.
              </p>
              <p>
                Üyeler, Napolyon.com ile ilgili mesajlarında kendileriyle ilgili veya alıcıları manipüle etmeye yönelik yanlış bilgiler sunmayacağını kabul eder.
              </p>
              <p>
                Napolyon.com kendi takdir yetkisinde üyelerin Napolyon.com'un Anti-Spam politikalarını uygulama ve davet gönderme izinlerini ihlal ettiğine inanırsa veya sahte kayıtlarla üye yaptığını tespit ederse, herhangi bir uyarı yapmaksızın üyeliklerini ve sahte kayıtları anında iptal eder.
              </p>
              <p>
                Napolyon.com Üyelik Sözleşmesi'nin 5.1.j maddesine göre;
              </p>
              <p>
                Üyeler işbu sözleşme kapsamında üçüncü kişilere gönderecekleri referans mesajlarının hukuka ve ahlaka aykırı unsurlar içermeyeceğini kabul, beyan ve taahhüt eder. Üyeler gönderecekleri elektronik postalar ile üçüncü kişilerce istenmeyen elektronik posta iletişimi olarak nitelendirilebilecek yoğunlukta bir iletişimde bulunmayacaklarını kabul, beyan ve taahhüt ederler. Üye'nin işbu madde hükmüne aykırı olarak yapacağı tüm elektronik posta iletişiminin sorumluluğunun kendisine ait olduğunu, her ne sebeple olursa olsun bu iletişimlerden kaynaklı olarak Napolyon.com'a bir dava ve talep yöneltilmesi halinde; bu dava ve talep'in Napolyon.com tarafından kendisine ihbar edilebileceği, Üye'nin Napolyon.com'un savunma yapması için gerekli olan her türlü bilgi ve içeriğini kendisine sağlayacağı, bahsi geçen dava ve talep sonucu Napolyon.com'un herhangi bir zarara uğraması durumunda bahsi geçen zararın Napolyon.com tarafından tazminat hakları saklı kalmak kaydıyla kendisine rücu ettirilebileceğini kabul, beyan ve taahhüt eder. Üye'nin işbu maddeye aykırı hareket etmesi sebebiyle Napolyon.com'un işbu sözleşmeyi herhangi bir bildirimde bulunmadan tek taraflı olarak fesh etme, üyeliği askıya alma veya iptal etme hakkını saklı tutmaktadır. Üye işbu madde hükmüne aykırı davranması durumunda üyelik sıfatına haiz olarak kazanmış olduğu ve/veya kazanacağı tüm haklardan Napolyon.com lehine sarfinizar ettiğini peşinen kabul, beyan ve taahhüt etmektedir.

              </p>

              <hr>

              <h1 class="turuncu-baslik">AntiSpam Policy</h1>
              <p>
                Electronic messages sent to a large number of recipients without their will are regarded as spam. "Napolyon.com" accepts its members' email addresses as their own personal information and undertakes not to send collective messages to these addresses without the relevant member's will.
              </p><p>
              Members, by accepting the membership agreement, undertake that they will not make any mailings regarding "Napolyon.com" to distribution lists, news groups and purchased email addresses.
            </p><p>
              Members accept that in their messages regarding "Napolyon.com", they will not provide any false information about themselves or in the nature of manipulating other recipients.
            </p> <p>
              In the event that "Napolyon.com", in its sole discretion, believes that the members violate their permission to apply its Anti-Spam policies or to send invitations or that the membership of the member relies on fake record, "Napolyon.com" shall be entitled to cancel the membership and the fake records immediately and without any warning.
            </p><p>
              Members fully accept to abide the terms and conditions of Article 5.1.j of "Napolyon.com" Membership Agreement which read as follows:
            </p> <p>
              "Members accept, declare and undertake that the reference messages that they shall send to third parties in accordance with this Agreement shall not include any illegal or immoral elements. Members accept, declare and undertake that they will not make any communications by the electronic mails that they would send which could be defined as undesired electronic mail communication by third parties. Members accept, declare and undertake that the sole responsibility for all the electronic mail communication that they will make contrary to this article, that in the event that any case or claim for any reason is subjected to "Napolyon.com" for such communications, "Napolyon.com" shall notify them such case or claim, that they shall immediately provide any information or content necessary for the defense immediately so as not to miss the required legal period, that in the event that "Napolyon.com" suffers any damages resulting from such case or claim, "Napolyon.com" shall recourse such damages to them, save for the indemnity right of "Napolyon.com". In the event that the Members act contradictory to this Article, the right of "Napolyon.com" to unilaterally and without any further notice terminate this Agreement, suspend or cancel the membership is reserved. The Members accept, declare and undertake that in case that they act contradictory to this Article, they in advance waive in favor of "Napolyon.com" all the rights they have qualified and/or will qualify as a result of their membership."
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- questions section end -->

    <!-- Start Contact -->
    <t-up-footer></t-up-footer>
    <t-footer></t-footer>
  </div>

</template>
<script>
import Footer from "@/view/layouts/Footer";
import upFooter from "@/view/layouts/upFooter";
import Header from "@/view/layouts/Header";
import feather from 'feather-icons';
export default {
  name: "AntiSpam",
  components: {
    't-footer': Footer,
    't-up-footer': upFooter,
    't-header': Header,
  },
  mounted() {
    window.scrollTo(0,0);
    feather.replace();
  },
}
</script>

<style scoped>

</style>